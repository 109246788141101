import React from 'react';
import { Card, Image } from 'react-bootstrap';
// import { TutorSlider } from './index';
// import { TutorSliderMobile } from './index';

const tutors = [
    {
      "id": 2,
      "name": "dr. Cynthia Mayasari",
      "alumni": "FK Universitas Brawijaya",
      "bidang": "Preklinik, Ilmu Kesehatan Saraf, Obstetri dan Ginekologi",
      "start_teaching_year": 2014,
      "current_role": "Vice President, Direktur Regio Malang-Jakarta",
      "img_url":"https://beta.ingenioindonesia.com/public/assets/tutor-profile/dr-cynthia-mayasari.png"
    },
    {
      "id": 3,
      "name": "dr. Erlangga Araditya Satriyo",
      "alumni": "FK Universitas Brawijaya",
      "bidang": "Preklinik, HKEK-F, Ilmu Kedokteran Jiwa, Ilmu Penyakit Dalam, Obstetri dan Ginekologi",
      "start_teaching_year": 2018,
      "current_role": "Direktur Regio Surabaya",
      "img_url":"https://beta.ingenioindonesia.com/public/assets/tutor-profile/dr-erlangga-araditya-satriyo.png"
    },
    {
      "id": 4,
      "name": "dr. Gede Dewa Windu Sanjaya",
      "alumni": "FK Universitas Udayana",
      "bidang": "Ilmu Kesehatan Anak",
      "start_teaching_year": 2016,
      "current_role": "Direktur Regio Bali",
      "img_url":"https://beta.ingenioindonesia.com/public/assets/tutor-profile/dr-gede-dewa-windu-sanjaya.png"
    },
    {
      "id": 6,
      "name": "dr. Arizal Novrianto Rachman",
      "alumni": "FK Universitas Brawijaya",
      "bidang": "Preklinik, Ilmu Kesehatan Saraf",
      "start_teaching_year": 2020,
      "current_role": "Marketing Regional Staff Malang-Jakarta",
      "img_url":"https://beta.ingenioindonesia.com/public/assets/tutor-profile/dr-aizal-novrianto-r.png"
    },
    {
      "id": 7,
      "name": "dr. Christianto Samuel Pieter Tangkau",
      "alumni": "FK Universitas Hang Tuah",
      "bidang": "Preklinik, Ilmu Penyakit Dalam, Telinga Hidung Tenggorok-Kepala Leher",
      "start_teaching_year": 2018,
      "current_role": "Marketing Regional Staff Surabaya",
      "img_url":"https://beta.ingenioindonesia.com/public/assets/tutor-profile/dr-christianto-samuel-pieter-tangkau.png"
    },
    {
      "id": 8,
      "name": "dr. Rachma Fariza Pramalia",
      "alumni": "FK Universitas Islam Malang",
      "bidang": "Preklinik, Ilmu Kedokteran Jiwa, Ilmu Kesehatan Jantung dan Paru",
      "start_teaching_year": 2019,
      "current_role": "Subregional Staff Malang",
      "img_url":"https://beta.ingenioindonesia.com/public/assets/tutor-profile/dr-rachma-fariza-pramalia.png"
    },
    {
      "id": 9,
      "name": "dr. Faradila Khoirun Nisa Hakim",
      "alumni": "FK Universitas Mataram",
      "bidang": "Ilmu Kedokteran Jiwa, Ilmu Kesehatan Kulit dan Kelamin",
      "start_teaching_year": 2018,
      "current_role": "Subregional Staff Surabaya",
      "img_url":"https://beta.ingenioindonesia.com/public/assets/tutor-profile/dr-faradila-khoirun-nisa-hakim.png"
    },
    {
      "id": 10,
      "name": "dr. I Gusti Ngurah Putra Satria Aryawan",
      "alumni": "FK Universitas Wijaya Kusuma Surabaya",
      "bidang": "Ilmu Bedah",
      "start_teaching_year": 2017,
      "current_role": "Tutor  Ingenio Indonesia",
      "img_url":"https://beta.ingenioindonesia.com/public/assets/tutor-profile/dr-i-gusti-ngurah-putra-satria-aryawan.png"
    },
    {
      "id": 11,
      "name": "dr. Novelita Mesah",
      "alumni": "FK Universitas Brawijaya",
      "bidang": "Ilmu Kedokteran Jiwa, Ilmu Kesehatan Kulit dan Kelamin",
      "start_teaching_year": 2014,
      "current_role": "Tutor  Ingenio Indonesia",
      "img_url":"https://beta.ingenioindonesia.com/public/assets/tutor-profile/dr-novelita-mesah.png"
    },
    {
      "id": 12,
      "name": "dr. Allison Joseasta Marsya Permana",
      "alumni": "FK Universitas Hang Tuah",
      "bidang": "Preklinik, Ilmu Kesehatan Anak",
      "start_teaching_year": 2018,
      "current_role": "Tutor  Ingenio Indonesia",
      "img_url":"https://beta.ingenioindonesia.com/public/assets/tutor-profile/dr-allison-joseasta-marsya-permana.png"
    }
]

export default function TutorCards() {
    let baseURL = window.location.origin;
    const Column = {
        borderRadius: "10px",
        width: 250,
        height: 50,
        padding: "25px",
    };
    return (
        <React.Fragment>
            {
                tutors.map((item, i) => {
                    return (
                        <div className="col-xl-3 col-md-6 col-sm-1 mb-4" style={{ paddingBottom: '10vh' }}>
                            <Card className="testimoni-slider mx-auto" style={{ border: 'none', backgroundColor: 'transparent', paddingTop: '7vh' }}>
                                <Card.Body style={{ padding: '0', background: 'linear-gradient(180deg, #F8CA2B 0%, #FCA826 100%)', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }}>
                                    <Card.Title>
                                        <div className="col-12 py-4">
                                            <img src={item.img_url} style={{ marginTop: '-45%', marginBottom: '-20%', marginRight: 'auto', marginLeft: 'auto' }}></img>
                                        </div>
                                    </Card.Title>
                                </Card.Body>
                                <Card.Body style={{ backgroundColor: '#FFFF', borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', boxShadow: '0 0 8px -3px black' }}>
                                    <Card.Text>
                                        <div className="row">
                                            <div className="col-12 px-5" style={{ textAlign: 'center' }}>
                                                <p style={{ color: '#000', fontSize: '16pt', fontFamily: 'Quicksand', fontWeight: 700 }}>
                                                    {item.name}
                                                </p>
                                                <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    {item.alumni}
                                                </p>
                                                <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    {item.bidang}
                                                </p>
                                                <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    {item.start_teaching_year}
                                                </p>
                                                <p style={{ color: '#000', fontSize: '14pt', fontFamily: 'Quicksand', fontWeight: 400 }}>
                                                    {item.current_role}
                                                </p>
                                            </div>
                                        </div>
        
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    )
                })
            }
        </React.Fragment>
    )
}

