import React, { useState } from 'react';
import {
  SideBar
} from '../../components/layout/dashboard';
import Button from '@restart/ui/esm/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useLocation } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import { BsCartFill } from 'react-icons/bs';
import { addCommas } from '../../helpers/utils'
import EcourseService from '../../services/ecourse'
import moment from 'moment-timezone';
import Swal from 'sweetalert2';


export default function TransactionCourse () {
  document.title = "INGENIO Indonesia";

  const [isLoading, setIsLoading] = useState(false)

  const location = useLocation();
  const { id, kode_ecourse, name, price, rate, desc } = location.state;

  const renderRating = (val) => {
    let stars = [];
    let unlistCount = 5-val
    for (let i = 1; i <= val; i++) {
        
      stars.push(<span class="fas fa-star active"></span>)
    }

    for (let j = 1; j <= unlistCount; j++) {
      stars.push(<span class="fas fa-star"></span>)
    }

    return stars;
  }

  const orderEcourse = (id) => {
    setIsLoading(true)
    const body = {
      id_ecourse: id
    }

    EcourseService.orderEcourseParticipant(body).then(resp => {
      if(resp.data.success === true) {
        const response = resp.data

        EcourseService.getListEcourseParticipant().then(trans => {
          const respTransactions = trans.data.data
          const id = respTransactions[0].id
          setIsLoading(false)

          window.location.replace(`/dashboard/course-tersedia/beli/pembayaran/proses?transaction_id=${id}`)

        }).catch(err => {
          console.error(err)
          setIsLoading(false)
          let msg = 'Internal Server Error'
          if(err.response) {
              if(err.response.data.message) {
                  msg = err.response.data.message
              }
          }
  
          Fail(msg)
        })

        return;
      }
      setIsLoading(false)

      Swal.fire({  
        title: 'Gagal Order',  
        text: resp.data.message,  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
      }); 
    }).catch(err => {
      console.error(err)
      setIsLoading(false)
      let msg = 'Internal Server Error'
      if(err.response) {
          if(err.response.data.message) {
              msg = err.response.data.message
          }
      }

      Fail(msg)
    })
    
  }

  const Fail = (message) => {
    Swal.fire({  
        title: 'Gagal!',  
        text: message,  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
    });   
  }

  return(
    <React.Fragment>
      <SideBar />
      <div className="md:ml-64" style={{ backgroundColor: '#F3F4F6', minWidth: '100vh' }}>
        {/* <div className="bg-orange px-3 md:px-8 py-1" style={{height: '14vh'}}>
          <div className="container md:pr-8 md:pl-10">
            <h3 className="text-left tracking-wider title-dashboard">Pembayaran Course</h3>
          </div>           
        </div> */}

        <div className="container md:pr-8 md:pl-10"  style={{position: 'absolute', top: '80px'}}>
          <h3 className="text-left tracking-wider title-dashboard">Pembayaran Course</h3>
        </div>  

        <div className="px-3 md:px-8 pb-5">
          <div className="container mx-auto max-w-full">
            <div className="row">
              <div className="col-12 mb-4">
                <Breadcrumb className="breadcrumbss my-4">
                  <Breadcrumb.Item href="/dashboard/course-tersedia">E-Course Tersedia</Breadcrumb.Item>
                  <Breadcrumb.Item href="/dashboard/course-tersedia/beli">Detail E-Course</Breadcrumb.Item>
                  <Breadcrumb.Item active><span style={{fontWeight: 'bold'}}>Pembayaran E-Course</span></Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <div className="col-12">
                <div className="cards pd-InJurnal">
                  <div className="row">
                    <div className="col-12 mb-4">
                      <div className="title-20-700">Pembayaran E-Course Via Virtual Akun</div>
                      <div className="borderBottomGrey"></div>
                    </div>
                    <div className="col-lg-7 BuyCourse">
                      <div className="flex mt-2">
                        <div className="name">Nama E-Course</div>
                        <div className="space">:</div>
                        <div className="desc">{name}</div>
                      </div>
                      <div className="flex mt-2">
                        <div className="name">Harga E-Course</div>
                        <div className="space">:</div>
                        <div className="desc">{`Rp ${addCommas(price)}`}</div>
                      </div>
                      <div className="flex mt-2">
                        <div className="name">Rating</div>
                        <div className="space">:</div>
                        <div className="desc rating flex">
                          {renderRating(Math.round(rate))}
                        </div>
                      </div>
                      <div className="flex mt-2">
                        <div className="name">Deskripsi</div>
                        <div className="space">:</div>
                        <div className="desc">{desc}</div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <p style={{fontSize:"18px !important", lineHeight: "22px", letterSpacing: "0.06em", color: "#000000", fontWeight: 'bold', marginTop: '8px'}}>Metode Pembayaran ke Bank :</p> <br />
                      <label class="virtual" for="mandiri">
                        <input type="radio" name="virtual" id="mandiri" />
                        <div class="virtual-content">                              
                          <div class="virtual-details">
                            <img loading="lazy" src="/assets/landing/Mandiri_logo 1.png" alt="" />
                            <p className="mt-2">Mandiri Virtual Account</p>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div className="col-lg-6 col-md-6 col-xs-12 px-5">
                      <Button type="submit" className="btnOrange3" onClick={() => {orderEcourse(id)}}>
                        <span className="txt-btn flex gap-2">Lanjutkan Pembayaran</span>
                      </Button>
                      <br /><br />
                      <Button onClick={() => {
                        window.open('https://beta.ingenioindonesia.com/public/assets/panduan_pembayaran_va_ingenio.pdf', '_blank')
                      }} className="panduan my-3">Panduan Pembayaran</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </React.Fragment>
    
  )
}