import React from 'react';
import ReactPlayer from 'react-player';

export default function HeroPlayer (props) {
  return(
    <React.Fragment>
      <div className="player-wrapper" style={{marginBottom: '5rem'}}>
        <ReactPlayer
          url="https://beta.ingenioindonesia.com/public/assets/reels_top_score.mp4"
          className="testimoni-player"
          playing={false}
          controls={true}
          width="100%"
          height="100%"
          volume={1}
          light={true}
        />
      </div>
    </React.Fragment>
  )
}