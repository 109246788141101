import Button from '@material-tailwind/react/Button';
import React, { useState, useEffect } from 'react';
import { FormGroup, FormControl, Form, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import { BsArrowLeftShort, BsFillTrashFill, BsFillPlusCircleFill, BsFillCloudArrowDownFill } from 'react-icons/bs';
import { FiDownload } from 'react-icons/fi';
import { HiOutlineDownload } from 'react-icons/hi';
import AkademikSidebar from '../../../../components/layout/dashboard/akademik/AkademikSideBar';
import AuthService from '../../../../services/auth';
import ElearningService from '../../../../services/elearning'
import CustomDatatable from '../../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default function DetailEvaluasiAkademik() {
    document.title = "INGENIO Indonesia";
    var baseUrl = window.location.origin;

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // Filtering data
    const [isLoading, setIsLoading] = useState(true);
    const [hasNext, setHasNext] = useState(false)
    const [hasPrev, setHasPrev] = useState(false)
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [kelasData, setKelasData] = useState([]);

    const { id, kelasId } = useParams();

    const dropdown = {
        borderRadius: "10px",
        height: 50,
        padding: "25px",
    };
    const columns = [
        {
            name: 'NO.',
            field: 'no',
            index: true,
            selector: row => row.no,
            sortable: true,
            minWidth: '1px'
        },
        {
            name: 'NAMA KELAS',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas_jadwal.kelas.nama_kelas}</td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'NAMA PROGRAM',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas_jadwal.kelas.elearning_program.nama_program}</td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'NAMA MATERI',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.kelas_jadwal.nama_materi}</td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'TOTAL PARTICIPANT',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.total_participant}</td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'GLOBAL SCORE',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.total_global_score}</td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'MATERI/SLIDE SCORE',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.total_material_score}</td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'LAYANAN/FASILITAS SCORE',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.total_facility_score}</td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'SISTEM BELAJAR SCORE',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.total_learning_system_score}</td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        },
        {
            name: 'DISIPLIN SCORE',
            render: true,
            component: (row) => {
                return (
                    <td className='main-table__bodyRow'>{row.total_discipline_score}</td>
                )
            },
            selector: row => row.tanggal,
            sortable: true,
            // width: '15vw',
        },
        {
          name: 'Aksi',
          render: true,
          component: (row) => {
              return (
                  <td className='main-table__bodyRow'>
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                          <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Download</Tooltip>}>
                            <Link to='#' onClick={() => {
                              downloadDetailEvaluasi(row.id)
                            }} className="btnCircleBlack mr-3" ><HiOutlineDownload size="22"/></Link>
                          </OverlayTrigger>
                      </div>
                  </td>
              )
          },
          selector: row => row.tanggal,
          sortable: true,
          // width: '15vw',
      },
    ];
    const data = [
        {
            no: 1,
            jumlah: '100',
            score: '330',
            materi: 'Lorem ipsum.',
            fasilitas: 'Lorem ipsum.',
            sistem: 'Lorem ipsum.',
            kedisiplinan: 'Lorem ipsum.',
            aksi: <Link className="btnTable gap-2" to="#">Download<FiDownload /></Link>,
        },
    ];

    const customStyles = {
        table: {
          style: {
            borderRadius: '15px',
            border: '1px solid #BDC1C9',
          },
        },
        head: {
          style: {
            fontSize: '1.25rem',
            textTransform: "uppercase",
            color: '#6B7280',
            backgroundColor: '#f3f4f6',
            border: '1px solid #BDC1C9',
            borderTopLeftRadius: '15px',
            borderTopRightRadius: '15px',
          },
        },
        pagination: {
          style: {
            color: '#6B7280',
            fontSize: '1.1rem',
            minHeight: '56px',
            backgroundColor: '#f3f4f6',
            border: '1.25px solid #BDC1C9',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          },
        },
    };

    const downloadDetailEvaluasi = (evaluasiId) => {
        setIsLoading(true)
        ElearningService.downloadDetailEvaluasiTutor(evaluasiId).then(resp => {
            const response = resp.data;
            const url = `https://beta.ingenioindonesia.com/public/assets/${response.data}`
    
            window.open(url, '_blank')
            
            setIsLoading(false)
        }).catch(err => {
            let msg = 'Internal Server Error'
            setIsLoading(false)
            console.error(err)
            if(err.response) {
                if(err.response.data.message) {
                    msg = err.response.data.message
                }
            }
    
            Fail(msg)
        })
      }
    
      const getListKelas = (params = '') => {
        setIsLoading(true)
        AuthService.getListJadwalKelasByUserIdAndKelasAkademik(id, kelasId, params).then(resp => {
            const response = resp.data;
            setKelasData(response.data);
    
            setPage(response.meta.page)
            setPerPage(response.meta.perPage)
            setCount(response.meta.count)
    
            setHasNext(response.meta.hasNext)
            setHasPrev(response.meta.hasPrev);
            
            setIsLoading(false)
        }).catch(err => {
            let msg = 'Internal Server Error'
            setIsLoading(false)
            console.error(err)
            if(err.response) {
                if(err.response.data.message) {
                    msg = err.response.data.message
                }
            }
    
            Fail(msg)
        })
      }
    
      const Fail = (message) => {
        Swal.fire({  
            title: 'Gagal!',  
            text: message,  
            icon: 'warning',  
            showCancelButton: true,  
            cancelButtonText: 'Kembali',           
            showConfirmButton: false,  
        });   
      }
    
      useEffect(() => {
        getListKelas()
      }, [])

    return (
        <React.Fragment>
            <AkademikSidebar />
            <div className="md:ml-64 tutorDataKelas" style={{ backgroundColor: '#F3F4F6', minHeight: '100vh' }}>
                <div className="bg-orange md:px-8 pb-4" style={{height: '24vh', position: 'relative', padding: '0 30px 30px 30px'}}>
                    <h1 className="tracking-wider title-26-600">Detail Evaluasi Tutor</h1>
                    <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Staff Akademik INGENIO !</h2>
                    <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
                </div>

                <div className="px-3 md:px-8 mt-4 pb-5">
                    <div className="container mx-auto max-w-full">
                        <div className="justify-start mb-4">
                            <Link to="/dashboard/akademik/e-learning/evaluasi-tutor/detail">
                                <div className="navBack">
                                    <BsArrowLeftShort size="19px"/>Kembali
                                </div> 
                            </Link>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-4">
                                <FormGroup>
                                    <div className="SearchTable">
                                    <input  onChange={(e) => {
                                        setKeyword(e.target.value)
                                    }}type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                                    <Button onClick={() => {
                                        getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                                    }} className="btnSearchTable">Cari</Button>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-12">
                                {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                                <CustomDatatable 
                                    filteredItems={kelasData} 
                                    columns={columns}
                                    isLoading={isLoading}
                                    page={page}
                                    perPage={perPage}
                                    hasPrev={hasPrev}
                                    hasNext={hasNext}
                                    count={count}
                                    onTableChange={(e, data) => {
                                        switch (e) {
                                        case 'rowPerPageChange':
                                            setPerPage(data.perPage)
                                            getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                            break;
                                        case 'rowPageChange':
                                            setPage(data.page)
                                            getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                            break;
                                        
                                        default:
                                            break;
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >

    )
}