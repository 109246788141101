import React from 'react';
import { useState, useEffect } from 'react';
import AkuntanSideBar from '../../../components/layout/dashboard/akuntan/AkuntanSidebar';
import Button from '@material-tailwind/react/Button';
import { FormGroup, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { GrFormView } from 'react-icons/gr';
import { FiDownload } from 'react-icons/fi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import TryoutService from '../../../services/tryout'
import CustomDatatable from '../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function PaymentTryoutAkuntan () {
  document.title = "INGENIO Indonesia";
  const [showSidebar, setShowSidebar] = useState('-left-64');
  var baseUrl = window.location.origin;

  // Filtering data
  const [isLoading, setIsLoading] = useState(true);
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [kelasData, setKelasData] = useState([]);

  const columns = [
    {
        name: 'NO.',
        field: 'no',
        index: true,
        selector: row => row.no,
        sortable: true,
        minWidth: '1px'
    },
    {
        name: 'KODE TRANSAKSI',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{`#${row.id}`}</td>
            )
        },
        selector: row => row.tanggal,
        sortable: true,
        // width: '15vw',
    },
    {
      name: 'TANGGAL TRANSAKSI',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>{moment(row.transaction_at).format('LL')}</td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
  },
    {
      name: 'KODE PESERTA',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>{`EC${row.id_user.split('-')[0].toUpperCase()}`}</td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
  },
  {
    name: 'NAMA PESERTA',
    render: true,
    component: (row) => {
        return (
            <td className='main-table__bodyRow'>{row.full_name}</td>
        )
    },
    selector: row => row.tanggal,
    sortable: true,
    // width: '15vw',
},
{
    name: 'KODE TRYOUT',
    render: true,
    component: (row) => {
        return (
            <td className='main-table__bodyRow'>{row.kode_tryout}</td>
        )
    },
    selector: row => row.tanggal,
    sortable: true,
    // width: '15vw',
},
{
  name: 'NAMA TRYOUT',
  render: true,
  component: (row) => {
      return (
          <td className='main-table__bodyRow'>{row.nama_tryout}</td>
      )
  },
  selector: row => row.tanggal,
  sortable: true,
  // width: '15vw',
},
{
  name: 'HARGA',
  render: true,
  component: (row) => {
      return (
          <td className='main-table__bodyRow'>{`Rp ${row.subtotal}`}</td>
      )
  },
  selector: row => row.tanggal,
  sortable: true,
  // width: '15vw',
},
{
  name: 'STATUS',
  render: true,
  component: (row) => {
      return (
          <td className='main-table__bodyRow'>
            {
              row.transaction_status === 'success' ?
              <span className="btnTableDone">Sukses</span>
              :
              <span className="btnTablePending">Pending</span>
            }
          </td>
      )
  },
  selector: row => row.tanggal,
  sortable: true,
  // width: '15vw',
},
  {
      name: 'Aksi',
      render: true,
      component: (row) => {
          return (
              <td className='main-table__bodyRow'>
                {
                  row.transaction_status === 'pending' ?
                  <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Views</Tooltip>}><Link className="btnCircleBlack" to={`/dashboard/akuntan/payment-process?transaction_id=${row.id}&single_view=true`}><GrFormView size="32"/></Link></OverlayTrigger>
                  :
                  <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Views</Tooltip>}><Link className="btnCircleBlack" to={`/dashboard/akuntan/payment-process/sukses?transaction_id=${row.id}&single_view=true`}><GrFormView size="32"/></Link></OverlayTrigger>
                }
              </td>
          )
      },
      selector: row => row.tanggal,
      sortable: true,
      // width: '15vw',
  },
];
  const data = [
    {
        no: 1,
        kodetr: '#88',
        kode: '11223344',
        nama: 'lorem',
        kodetry: 'TO778899',
        namatry: 'TryOut A',
        harga: 'Rp. 120.000',
        status: <span className="btnTableDone">Sukses</span>,
    },
    {
        no: 2,
        kodetr: '#89',
        kode: '11223344',
        nama: 'lorem',
        kodetry: 'TO778899',
        namatry: 'TryOut B',
        harga: 'Rp. 150.000',
        status: <span className="btnTablePending">Pending</span>,
    },
  ];

  const customStyles = {
      table: {
        style: {
          borderRadius: '15px',
          border: '1px solid #BDC1C9',
        },
      },
      head: {
        style: {
          fontSize: '1.25rem',
          textTransform: "uppercase",
          color: '#6B7280',
          backgroundColor: '#f3f4f6',
          border: '1px solid #BDC1C9',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
        },
      },
      pagination: {
        style: {
          color: '#6B7280',
          fontSize: '1.1rem',
          minHeight: '56px',
          backgroundColor: '#f3f4f6',
          border: '1.25px solid #BDC1C9',
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px',
        },
      },
  };

  const downloadListTransaction = (params = '') => {
    setIsLoading(true)
    TryoutService.downloadTransactionAkuntan('tryout', params).then(resp => {
        const response = resp.data;
        const url = `https://beta.ingenioindonesia.com/public/assets/${response.data}`

        window.open(url, '_blank')
        
        setIsLoading(false)
    }).catch(err => {
        let msg = 'Internal Server Error'
        setIsLoading(false)
        console.error(err)
        if(err.response) {
            if(err.response.data.message) {
                msg = err.response.data.message
            }
        }

        Fail(msg)
    })
  }

  const getListKelas = (params = '') => {
    setIsLoading(true)
    TryoutService.getTryoutTransactionAdmin(params).then(resp => {
        const response = resp.data;
        setKelasData(response.data);

        setPage(response.meta.page)
        setPerPage(response.meta.perPage)
        setCount(response.meta.count)

        setHasNext(response.meta.hasNext)
        setHasPrev(response.meta.hasPrev);
        
        setIsLoading(false)
    }).catch(err => {
        let msg = 'Internal Server Error'
        setIsLoading(false)
        console.error(err)
        if(err.response) {
          if(err.response.data.message) {
            msg = err.response.data.message
          }
        }

        Fail(msg)
    })
  }

  const Fail = (message) => {
    Swal.fire({  
        title: 'Gagal!',  
        text: message,  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
    }); 
  }

  useEffect(() => {
    getListKelas()
  }, [])

  return(
    <React.Fragment>
      <AkuntanSideBar />
      <div className="md:ml-64 styleHead" style={{ backgroundColor: '#F3F4F6' }}>
        <div className="bg-orange md:px-8 pb-5" style={{height: '20vh', position: 'relative', padding: '0 30px 30px 30px'}}>
            <h1 className="tracking-wider title-24-600">Payment TryOut</h1>
            {/* <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2> */}
            <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
        </div>

        <div className="px-3 md:px-8 pb-5">
            <div className="container mx-auto max-w-full">
                <div className="row mt-5">
                  <div className="col-12 my-2">
                    <FormGroup>
                      <div className="flexx">
                        <div className="SearchTable">
                            <input onChange={(e) => {
                              setKeyword(e.target.value)
                            }} type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                            <Button onClick={() => {
                                getListKelas(`page=${page}&perPage=${perPage}&keyword=${keyword}&dateFrom=${dateFrom}&dateTo=${dateTo}`)
                            }}  className="btnSearchTable">Cari</Button>
                        </div>
                        <div className="containerRange">
                          <div className="rangeDownload">
                            <input onChange={(e) => setDateFrom(e.target.value)} type="date" class="formInput" />
                          </div>
                          <div className="rangeDownload relative ml-1">
                            <input onChange={(e) => setDateTo(e.target.value)} type="date" class="formInput" />
                            <div className="dashAbsolute2"></div>  
                          </div>
                          <div className="rangeDownload">
                            <Button onClick={() => downloadListTransaction(`dateFrom=${dateFrom}&dateTo=${dateTo}`)} className="btnOrange3">
                              <div className="titleBtnOrange">
                                <FiDownload size="22px" className="mr-2"/>Download
                              </div> 
                            </Button>
                          </div>
                        </div>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-12 mt-3">
                      {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                      <CustomDatatable 
                          filteredItems={kelasData} 
                          columns={columns}
                          isLoading={isLoading}
                          page={page}
                          perPage={perPage}
                          hasPrev={hasPrev}
                          hasNext={hasNext}
                          count={count}
                          onTableChange={(e, data) => {
                              switch (e) {
                              case 'rowPerPageChange':
                                  setPerPage(data.perPage)
                                  getListKelas(`page=${page}&perPage=${data.perPage}&keyword=${keyword}&dateFrom=${dateFrom}&dateTo=${dateTo}`)
                                  break;
                              case 'rowPageChange':
                                  setPage(data.page)
                                  getListKelas(`page=${data.page}&perPage=${perPage}&keyword=${keyword}&dateFrom=${dateFrom}&dateTo=${dateTo}`)
                                  break;
                              
                              default:
                                  break;
                              }
                          }}
                      />
                  </div>
                </div>
            </div>
        </div>

      </div>
    </React.Fragment>
    
  )
}