import axios from "axios";

const API_URL = `${process.env.REACT_APP_TRYOUTURL}`

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
class TryoutService {
  async getListUserRankingAdmin(id, params = '') {
    return axios.get(API_URL + `user-ranking/admin/get-by-subtryout/${id}?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  async getListTryoutAdmin(params = '') {
    return axios.get(API_URL + `tryout/admin/get?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  async getListSubTryoutAdmin(id, params = '') {
    return axios.get(API_URL + `subtryout/admin/get-by-tryout/${id}?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  createProgramAdmin(body) {
    return axios.post(API_URL + `program/admin/create`, body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  updateProgramAdmin(id, body) {
    return axios.put(API_URL + `program/admin/update/${id}`, body, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  deleteProgramAdmin(id) {
    return axios.delete(API_URL + `program/admin/delete/${id}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  getListProgram(params = '') {
    return axios.get(API_URL + `program/admin?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  // AKADEMIK AREA
  downloadHasilTryout(subtryoutId) {
    return axios.get(API_URL + 'user-ranking/akademik/download/'+subtryoutId, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  getListSubtryoutByTryoutId(tryoutId) {
    return axios.get(API_URL + 'subtryout/akademik/subtryout-by-tryout/'+tryoutId, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  getHasilTryoutPesertaSummaryPerSubtryoutAkademik(userId, tryoutId) {
    return axios.get(API_URL + 'pengerjaan-tryout/akademik/hasil-per-subtryout/user/'+userId+'/tryout/'+tryoutId, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  // END AKADEMIK AREA

  // TRYOUT API
  getHasilTryoutPesertaSummaryPerSubtryoutParticipant(tryoutId) {
    return axios.get(API_URL + 'pengerjaan-tryout/participant/hasil-per-subtryout/tryout/'+tryoutId, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  getHasilTryoutPesertaAkademikPersubtryout(userId, subtryoutId) {
    return axios.get(API_URL + 'pengerjaan-tryout/akademik/subtryout-hasil-peserta/'+subtryoutId+'/user/'+userId, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  getRankingPesertaSubtryoutAkademik(userId, subtryoutId) {
    return axios.get(API_URL + 'user-ranking/akademik/tryout-akademik/rank-subtryout/'+subtryoutId+'/user/'+userId, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  getHasilTryoutPesertaAkademik(userId, tryoutId) {
    return axios.get(API_URL + 'user-ranking/akademik/tryout-akademik/rank/'+userId+'/tryout/'+tryoutId, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  getPesertaTryout(userId, tryoutId) {
    return axios.get(API_URL + 'user-tryout/akademik/detail-peserta/'+userId+'/tryout/'+tryoutId)
  }
  getAllTryout(params = '') {
    return axios.get(API_URL + 'tryout/get?' + params);
  }

  getAllTryoutHistory() {
    return axios.get(API_URL + 'tryout/get/past');
  }

  getAllTryoutBank() {
    return axios.get(API_URL + 'tryout/get/bank');
  }

  // FETCH TRYOUT TERSEDIA (AKTIF) DI DASHBOARD STUDENT
  getAllTryoutStudent() {
    return axios.get(API_URL + 'tryout/participant/get');
  }

  getTryoutDetail(id) {
    return axios.get(API_URL + 'tryout/get/' + id, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  createTryout(payload) {
    return axios.post(API_URL + 'tryout/create', payload);
  }

  updateTryout(id, payload) {
    return axios.put(API_URL + 'tryout/update/' + id, payload);
  }

  aktivasi(id, payload) {
    return axios.put(API_URL + 'tryout/aktivasi/' + id, payload);
  }

  deleteTryout(id) {
    return axios.delete(API_URL + 'tryout/delete/' + id);
  }
  // END TRYOUT API

  // PROGRAM API
  getAllProgram() {
    return axios.get(API_URL + 'program/akademik/dropdown');
  }

  getProgramDetail(id) {
    return axios.get(API_URL + 'program/get/' + id);
  }

  createProgram(payload) {
    return axios.post(API_URL + 'program/create', payload);
  }

  updateProgram(id, payload) {
    return axios.post(API_URL + 'program/update/' + id, payload);
  }

  deleteProgram(id) {
    return axios.delete(API_URL + 'program/delete/' + id);
  }
  // END PROGRAM API

  // SUBTRYOUT API
  getAllSubtryout() {
    return axios.get(API_URL + 'subtryout/get');
  }

  getSubtryoutByTO(id) {
    return axios.get(API_URL + 'subtryout/get/by-tryout/' + id);
  }

  getSubtryoutDetail(id) {
    return axios.get(API_URL + 'subtryout/get/' + id);
  }

  createSubtryout(payload) {
    return axios.post(API_URL + 'subtryout/create', payload);
  }

  updateSubtryout(id, payload) {
    return axios.put(API_URL + 'subtryout/update/' + id, payload);
  }

  updateDuration(id, payload) {
    return axios.put(API_URL + 'subtryout/change-duration/' + id, payload);
  }

  deleteSubtryout(id) {
    return axios.delete(API_URL + 'subtryout/delete/' + id);
  }

  tambahSoal(id) {
    return axios.put(API_URL + 'subtryout/tambah-soal/' + id);
  }

  uploadHasilLaboratorium(id, payload) {
    return axios.put(API_URL + 'subtryout/upload-hasil-lab/' + id, payload, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
        'Content-Type': "multipart/form-data",
      }
    })
  }
  // END SUBTRYOUT API

  // KOMPETENSI API
  getAllKompetensi(params = '') {
    // return axios.get(API_URL + 'kompetensi/get');
    return axios.get(API_URL + 'kompetensi/get?'+params, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  getKompetensiDetail(id) {
    return axios.get(API_URL + 'kompetensi/get/' + id);
  }

  createKompetensi(payload) {
    // return axios.post(API_URL + 'kompetensi/create', payload);
    return axios.post(API_URL + 'kompetensi/create', payload, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  updateKompetensi(id, payload) {
    // return axios.put(API_URL + 'kompetensi/update/' + id, payload);
    return axios.put(API_URL + 'kompetensi/update/' + id, payload, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  deleteKompetensi(id) {
    // return axios.delete(API_URL + 'kompetensi/delete/' + id);
    return axios.delete(API_URL + 'kompetensi/delete/' + id, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  // END KOMPETENSI API

  // BIDANG API
  getAllBidang(params = '') {
    // return axios.get(API_URL + 'bidang/get');
    return axios.get(API_URL + 'bidang/get?'+params, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  getBidangDetail(id) {
    return axios.get(API_URL + 'bidang/get/' + id);
  }

  createBidang(payload) {
    // return axios.post(API_URL + 'bidang/create', payload);
    return axios.post(API_URL + 'bidang/create', payload, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  updateBidang(id, payload) {
    // return axios.put(API_URL + 'bidang/update/' + id, payload);
    return axios.put(API_URL + 'bidang/update/' + id, payload, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  deleteBidang(id) {
    // return axios.delete(API_URL + 'bidang/delete/' + id);
    return axios.delete(API_URL + 'bidang/delete/' + id, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  // END BIDANG API

  // TOPIK API
  deleteGambarSoalByBanksoalIdAkademik(id){
    return axios.delete(API_URL + `banksoal/delete-gambar/soal/${id}`)
  }

  deleteGambarPembahasanByBanksoalIdAkademik(id) {
    return axios.delete(API_URL + `banksoal/delete-gambar/pembahasan/${id}`)
  }

  deleteGambarJawabanByBanksoalIdAkademik(id, key) {
    return axios.delete(API_URL + `banksoal/delete-gambar/jawaban/${id}/key/${key}`)
  }


  getAllTopik(params = '') {
    // return axios.get(API_URL + 'topik/get');
    return axios.get(API_URL + 'topik/get?'+params, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  getTopikDetail(id) {
    return axios.get(API_URL + 'topik/get/' + id);
  }

  createTopik(payload) {
    // return axios.post(API_URL + 'topik/create', payload);
    return axios.post(API_URL + 'topik/create', payload, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  updateTopik(id, payload) {
    // return axios.put(API_URL + 'topik/update/' + id, payload);
    return axios.put(API_URL + 'topik/update/' + id, payload, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  deleteTopik(id) {
    // return axios.delete(API_URL + 'topik/delete/' + id);
    return axios.delete(API_URL + 'topik/delete/' + id, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }
  // END TOPIK API

  // BANKSOAL API
  getBankSoal(id) {
    return axios.get(API_URL + 'banksoal/get/' + id)
  }

  updateBankSoal(id, payload) {
    return axios.put(API_URL + 'banksoal/update/' + id, payload)
  }

  createBankSoal(payload) {
    return axios.post(API_URL + 'banksoal/create', payload, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  deleteBankSoal(id) {
    return axios.delete(API_URL + 'banksoal/delete/' + id)
  }

  uploadGambarSoal(payload) {
    return axios.post(API_URL + 'banksoal/upload-gambar/soal', payload, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
        'Content-Type': "multipart/form-data",
      }
    })
  }

  uploadGambarPembahasan(payload) {
    return axios.post(API_URL + 'banksoal/upload-gambar/pembahasan', payload, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
        'Content-Type': "multipart/form-data",
      }
    })
  }

  uploadGambarJawaban(payload) {
    return axios.post(API_URL + 'banksoal/upload-gambar/jawaban', payload, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
        'Content-Type': "multipart/form-data",
      }
    })
  }
  // END BANKSOAL API

  // USER TRYOUT API
  getUserTryout(id) {
    return axios.get(API_URL + 'user-tryout/get-by-tryout/' + id)
  }

  // FETCH TRYOUT SAYA DI DASHBOARD STUDENT
  getMyTryout() {
    return axios.get(API_URL + 'user-tryout/participant/my-tryout', {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
        'Content-Type': "multipart/form-data",
      }
    })
  }

  getMyTryoutDetail(id) {
    return axios.get(API_URL + 'user-tryout/participant/my-tryout/' + id, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
        'Content-Type': "multipart/form-data",
      }
    })
  }
  // END USER TRYOUT API

  // BANKSOAL SUBTRYOUT API
  createBankSoalSubTO(payload) {
    return axios.post(API_URL + 'subtryout-banksoal/create', payload)
  }

  deleteBankSoalSubTO(id) {
    return axios.delete(API_URL + 'subtryout-banksoal/delete/' + id)
  }

  importBank (id, payload) {
    return axios.post(API_URL + 'subtryout-banksoal/import/' + id, payload)
  }
  // END BANKSOAL SUBTRYOUT API

  // PENGERJAAN TRYOUT API

  getListSummary() {
    return axios.get(API_URL + 'pengerjaan-tryout/participant/get-all-summary', {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  getParticipantCompletion (id) {
    return axios.get(API_URL + 'pengerjaan-tryout/participant/completion/' + id, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  getKerjakanDetail (id, subtryoutId) {
    return axios.get(API_URL + 'pengerjaan-tryout/participant/kerjakan/' + id + '/' + subtryoutId, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  kerjakanSoal(id, soal) {
    return axios.get(API_URL + 'pengerjaan-tryout/participant/kerjakan-tryout/' + id + '?page=' + soal, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  submitAnswer(payload) {
    return axios.post(API_URL + 'pengerjaan-tryout/participant/submit-answer-tryout', payload, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  getHasilTryout(id) {
    return axios.get(API_URL + 'pengerjaan-tryout/participant/subtryout-hasil/' + id, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }

  getHasilTryoutAll(id) {
    return axios.get(API_URL + 'pengerjaan-tryout/participant/tryout-hasil/' + id, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    });
  }
  // END PENGERJAAN TRYOUT API

  // USER RANKING

  getUserHasilTryoutBySubtryoutAkademik(userId, subtryoutId) {
    return axios.get(API_URL + 'pengerjaan-tryout/akademik/subtryout-hasil-peserta/'+subtryoutId+'/user/' + userId, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  getUserRankingAkademikSubtryout(id) {
    return axios.get(API_URL + 'user-ranking/akademik/subtryout-participant/rank/' + id, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  getUserRankingAkademikSubtryoutList(id, params = '') {
    return axios.get(API_URL + 'user-ranking/akademik/subtryout-participant/rank/' + id + '?' + params, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  getUserRankingTryout(id) {
    return axios.get(API_URL + 'user-ranking/akademik/tryout-participant/rank/' + id, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  getUserRankingParticipant(id) {
    return axios.get(API_URL + 'user-ranking/participant/tryout-participant/rank-subtryout/' + id, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  // END USER RANKING

  // Tinjauan

  getFirstTinjauan(param = '') {
    return axios.get(API_URL + `tinjauan/tinjauan-1${param}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  getSecondTinjauan(param = '') {
    return axios.get(API_URL + `tinjauan/tinjauan-2${param}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  getThirdTinjauan(param = '') {
    return axios.get(API_URL + `tinjauan/tinjauan-3${param}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  getFourthTinjauan(param = '') {
    return axios.get(API_URL + `tinjauan/tinjauan-4${param}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  getFifthTinjauan(param = '') {
    return axios.get(API_URL + `tinjauan/tinjauan-5${param}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  getSixthTinjauan(param = '') {
    return axios.get(API_URL + `tinjauan/tinjauan-6${param}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  getSeventhTinjauan(param = '') {
    return axios.get(API_URL + `tinjauan/tinjauan-7${param}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }


  // End Tinjauan

  downloadTransactionAkuntan(typeTransaction, params = '') {
    return axios.get(API_URL + `transaction/${typeTransaction}/admin/download?${params}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  getEcourseTransactionAdmin(param = '') {
    return axios.get(API_URL + `transaction/e-course/admin/get?${param}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  getElearningTransactionAdmin(param = '') {
    return axios.get(API_URL + `transaction/e-learning/admin/get?${param}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  getTryoutTransactionAdmin(param = '') {
    return axios.get(API_URL + `transaction/tryout/admin/get?${param}`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }

  downloadListParticipantTryoud(id) {
    return axios.get(process.env.REACT_APP_ELEARNINGURL + `kelas/admin/tryout-participant/get/${id}/download`, {
      headers: {
        "Authorization": "Bearer " + JSON.parse(localStorage.getItem("token")),
      }
    })
  }
  
}

export default new TryoutService();