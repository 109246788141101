import React from 'react';
import { useState, useEffect } from 'react';
import AdminSideBar from '../../../../components/layout/dashboard/admin/AdminSideBar';
import Button from '@material-tailwind/react/Button';
import { FormGroup, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { GrFormView } from 'react-icons/gr';
import { MdOutlineNoteAdd, MdOutlineAddCircleOutline } from 'react-icons/md';
import { HiOutlineDownload, HiOutlineTrash } from 'react-icons/hi';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import ElearningService from '../../../../services/elearning';
import CustomDatatable from '../../../../components/common/CustomDatatable';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';

export default function InformasiLogistikAdmin () {
  document.title = "INGENIO Indonesia";
  const [showSidebar, setShowSidebar] = useState('-left-64');
  var baseUrl = window.location.origin;

  const [isLoading, setIsLoading] = useState(true);
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [keyword, setKeyword] = useState('');
  const [userId, setUserId] = useState('');
  const [kelasSaya, setKelasSaya] = useState([])

  const columns = [
    {
        name: 'NO.',
        field: 'no',
        index: true,
        selector: row => row.no,
        sortable: true,
    },
    {
        name: 'NAMA PESERTA',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{row.nama_peserta}</td>
            )
        },
        selector: row => row.namakelas,
        sortable: true,
        // width: '15vw',
    },
    {
        name: 'NAMA KELAS',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{row.elearning_shipments[0].kelas.nama_kelas}</td>
            )
        },
        selector: row => row.namakelas,
        sortable: true,
        // width: '15vw',
    },
    {
        name: 'PROGRAM BIMBINGAN',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{row.elearning_shipments[0].kelas.elearning_program.nama_program}</td>
            )
        },
        selector: row => row.programbimbingan,
        sortable: true,
        // width: '15vw',
    },
    {
        name: 'KURIR PAKET',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{'Lion Parcel'}</td>
            )
        },
        selector: row => row.regio,
        sortable: true,
        // width: '15vw',
    },
    {
        name: 'TANGGAL PENGIRIMAN',
        selector: row => row.masaberlaku,
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>
                  {
                    row.shipment_date !== null ?
                    `${moment(row.shipment_date).format('DD-MM-YYYY')}`
                    :
                    '-'
                  }
                </td>
            )
        },
        sortable: true,
        // width: '15vw',
    },
    {
        name: 'NO. RESI PAKET',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>{row.resi}</td>
            )
        },
        selector: row => row.statuskelas,
        sortable: true,
    },
    {
        name: 'STATUS PAKET',
        render: true,
        component: (row) => {
            return (
                <td className='main-table__bodyRow'>
                  {
                    row.last_status_tracking === 'sent' ?
                    <span className="btnTableDone">Terkirim</span>
                    :
                    <span className="btnTablePending">Pending</span>
                  }
                </td>
            )
        },
        selector: row => row.statuskelas,
        sortable: true,
    },
];
  const data = [
    {
        no: 1,
        kode: '11223344',
        nama: 'lorem',
        kelas: 'Kelas A',
        program: 'lorem ipsum dolor.',
        tgl: '12-12-2021',
        resi: '000-000-0000',
        status: <span className="btnTableDone">Terkirim</span>,
    },
    {
        no: 2,
        kode: '11223344',
        nama: 'lorem',
        kelas: 'Kelas A',
        program: 'lorem ipsum dolor.',
        tgl: '12-12-2021',
        resi: '000-000-0000',
        status: <span className="btnTablePending">Pending</span>,
    },
  ];

  const customStyles = {
      table: {
        style: {
          borderRadius: '15px',
          border: '1px solid #BDC1C9',
        },
      },
      head: {
        style: {
          fontSize: '1.25rem',
          textTransform: "uppercase",
          color: '#6B7280',
          backgroundColor: '#f3f4f6',
          border: '1px solid #BDC1C9',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
        },
      },
      pagination: {
        style: {
          color: '#6B7280',
          fontSize: '1.1rem',
          minHeight: '56px',
          backgroundColor: '#f3f4f6',
          border: '1.25px solid #BDC1C9',
          borderBottomLeftRadius: '15px',
          borderBottomRightRadius: '15px',
        },
      },
  };

  const AreYouSure = () => {
      Swal.fire({  
          title: 'Sudah Yakin?',  
          text: 'Apakah Anda Yakin Ingin Menghapus Data Ini?',  
          icon: 'warning',  
          showCancelButton: true,  
          cancelButtonText: 'Tidak',  
          confirmButtonText: 'Ya'  
      })
  }

  const Succeed = () => {
      Swal.fire({  
          title: 'Data Berhasil Tersimpan !',  
          text: '',  
          icon: 'success',  
          showCancelButton: false,              
          confirmButtonText: 'OK'  
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      })
  }

  const getListKelasSaya = (params = '') => {
    setIsLoading(true)
    ElearningService.getListShipmentAdmin(params).then(resp => {
        if(resp.data.success === true) {
            const response = resp.data;
            setKelasSaya(response.data);

            setPage(response.meta.page)
            setPerPage(response.meta.perPage)
            setCount(response.meta.count)

            setHasNext(response.meta.hasNext)
            setHasPrev(response.meta.hasPrev);

            if(response.meta.userId) {
                setUserId(response.meta.userId)
            }
        }
        setIsLoading(false)
    }).catch(err => {
        setIsLoading(false)
        console.error(err)
    })
}

const downloadResiAdmin = () => {
    setIsLoading(true)
    ElearningService.downloadResiAdmin().then(resp => {
        const response = resp.data;
        const url = `https://beta.ingenioindonesia.com/public/assets/${response.data}`

        window.open(url, '_blank')
        
        setIsLoading(false)
    }).catch(err => {
        let msg = 'Internal Server Error'
        setIsLoading(false)
        console.error(err)
        if(err.response) {
            if(err.response.data.message) {
                msg = err.response.data.message
            }
        }

        Fail(msg)
    })
}

const Fail = (message) => {
    Swal.fire({  
        title: 'Gagal!',  
        text: message,  
        icon: 'warning',  
        showCancelButton: true,  
        cancelButtonText: 'Kembali',           
        showConfirmButton: false,  
    });   
  }

useEffect(() => {
    getListKelasSaya()
}, [])

  return(
    <React.Fragment>
      <AdminSideBar />
      <div className="md:ml-64 styleHead" style={{ backgroundColor: '#F3F4F6' }}>
        <div className="bg-orange md:px-8 pb-5" style={{height: '20vh', position: 'relative', padding: '0 30px 30px 30px'}}>
            <h1 className="tracking-wider title-24-600">Informasi Logistik</h1>
            {/* <h2 className="tracking-wider title-20-700 mt-5">Selamat Datang Tutor INGENIO !</h2> */}
            <img src={baseUrl + "/assets/logo/evaluasikelas.png"} className="img-opacity"></img>
        </div>

        <div className="px-3 md:px-8 pb-5">
            <div className="container mx-auto max-w-full">
                <div className="row mt-5">
                  <div className="col-12 my-2">
                    <FormGroup>
                      <div className="justify-start">
                        <div className="SearchTable">
                            <input value={keyword}
                                onChange={(e) => setKeyword(e.target.value)} type="text" name="" className="inputSearchTable" placeholder="&#x1F50E;&#xFE0E; Search..." id="" />
                            <Button onClick={() => {
                                getListKelasSaya(`page=${page}&perPage=${perPage}&keyword=${keyword}`)
                            }} className="btnSearchTable">Cari</Button>
                        </div>
                        <OverlayTrigger placement="top" overlay={<Tooltip id="button-tooltip-2">Download Informasi</Tooltip>}>
                          <Button onClick={() => downloadResiAdmin()} className="btnOrange2">
                              <div className="titleBtnOrange">
                                  <HiOutlineDownload size="23px" className="mr-2"/>Download
                              </div> 
                          </Button>
                        </OverlayTrigger>
                      </div>
                    </FormGroup>
                  </div>
                  <div className="col-12 mt-3">
                      {/* <DataTable columns={columns} customStyles={customStyles} data={data} pagination /> */}
                      <CustomDatatable 
                          filteredItems={kelasSaya} 
                          columns={columns}
                          isLoading={isLoading}
                          page={page}
                          perPage={perPage}
                          hasPrev={hasPrev}
                          hasNext={hasNext}
                          count={count}
                          onTableChange={(e, data) => {
                              switch (e) {
                              case 'rowPerPageChange':
                                  setPerPage(data.perPage)
                                  getListKelasSaya(`page=${page}&perPage=${data.perPage}&keyword=${keyword}`)
                                  break;
                              case 'rowPageChange':
                                  setPage(data.page)
                                  getListKelasSaya(`page=${data.page}&perPage=${perPage}&keyword=${keyword}`)
                                  break;
                              
                              default:
                                  break;
                              }
                          }}
                      />
                  </div>
                </div>
            </div>
        </div>

      </div>
    </React.Fragment>
    
  )
}